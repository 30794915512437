<template>
  <v-container fluid class="dashboard my-0 py-0">
    <v-row>
      <v-container fluid class="dashboard-content ma-0 pa-0 has-navigation">
        <v-row class="pa-0 height100" no-gutters>
          <v-col
            v-if="($vuetify.breakpoint.smAndUp && showZoneList) || ($vuetify.breakpoint.xs && !camera)"
            sm="auto"
            class="pa-0 pl-2 height100"
          >
            <zone-list
              id="zone-list"
              :zones="zones"
              :cameras="cameras"
              :camera="camera"
              :selected-zone="selectedZone"
              :selectedCameras="selectedCameras"
              @selectZone="selectZone"
              @select="selectCamera"
              @showMultiView="showMultiView"
              @toggleShow="showZoneListMethod"
            ></zone-list>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp || ($vuetify.breakpoint.smAndDown && camera)"
            class="pa-0 px-1 pb-2 height100"
          >
            <v-toolbar class="pt-1" flat height="42" color="#eee">
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn small :disabled="cameras.length < 1" icon :ripple="false" @click="toggleMaximize" class="mr-2">
                  <v-icon>fa fa-expand</v-icon>
                </v-btn>
                <div class="vl"></div>
                <v-btn
                  icon
                  small
                  :ripple="false"
                  :color="!multiView ? 'primary' : ''"
                  @click="showMultiView(1)"
                  class="mr-2"
                  :disabled="cameras.length < 1"
                >
                  <v-icon>fas fa-square</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  :ripple="false"
                  :color="!threeZone && multiView ? 'primary' : ''"
                  @click="showMultiView(2)"
                  class="mr-2"
                  :disabled="cameras.length < 1"
                >
                  <v-icon>fa fa-th-large</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  :ripple="false"
                  :color="threeZone && multiView ? 'primary' : ''"
                  @click="showMultiView(3)"
                  :disabled="cameras.length < 1"
                >
                  <v-icon>fa fa-th</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div
              ref="fullscreen"
              id="fullScreen"
              background="black"
              @change="fullscreenChange"
              v-if="multiView"
              class="multiViewGrid"
              :class="{ maximize: fullscreen, visibilityHidden: !$vuetify.breakpoint.mdAndUp }"
            >
              <div class="grid" :class="[{ gridFullScreen: fullscreen }]">
                <div
                  v-for="(selectedData, index) in allCameras"
                  :data-id="index"
                  :key="selectedData.name"
                  @drop="drop($event, selectedData.name, selectedData.value)"
                  @dragover="allowdrop"
                  class="multiViewPanel item"
                  :class="[
                    { threeZone: threeZone },
                    fullscreen ? (threeZone ? 'panelfullScreen3' : 'panelfullScreen2') : '',
                  ]"
                >
                  <div class="item-content">
                    <LiveVideo
                      v-if="selectedData.value"
                      :cursorHide="cursorHide"
                      :threeZone="threeZone"
                      :maximize="fullscreen"
                      :camera="selectedData.value"
                      :cameras="cameras"
                      type="hls"
                      :isMuted="selectedData.muted"
                      @removeVideo="removeVideo(selectedData.name)"
                      @audioPlay="audioPlay"
                      :rights="rights"
                      :isMultiview="true"
                    ></LiveVideo>
                    <div v-if="!selectedData.value" class="dragDrop">
                      {{ $t("Drag and Drop Your Camera Here") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-row
              v-if="!camera && !multiView"
              @drop="dropSingleView"
              @dragover="allowdrop"
              id="no-camera-selected"
              align="center"
              justify="center"
              class="hidden-sm-and-down fill-height pt-12"
            >
              <v-icon small>fa-arrow-left</v-icon>
              <p class="subheading mb-0 ml-2">{{ $t("Select a camera to start viewing live video.") }}</p>
            </v-row>
            <v-row
              @drop="dropSingleView"
              @dragover="allowdrop"
              v-if="camera && !multiView"
              id="live-video"
              class="pl-3"
            >
              <LiveVideo
                :maximize="fullScreenSingleView"
                :camera="camera"
                :cameras="cameras"
                type="hls"
                :rights="rights"
                :isMultiview="false"
                @emit-toggle-map="showMap = !showMap"
                @emit-gps-coordinates="(coordinates) => (cameraCoordinates = coordinates)"
              ></LiveVideo>
            </v-row>
            <v-overlay
              color="#eee"
              :class="[!showZoneList ? 'overlaySidePanelFull' : 'overlaySidePanel']"
              absolute
              opacity="1"
              :value="overlay"
            ></v-overlay>
          </v-col>
          <v-col v-if="showMap && !multiView && cameraCoordinates" class="mb-1 mt-11 mr-3 pl-0" cols="4">
            <Maps :position="cameraCoordinates"></Maps>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import ZoneList from "@/components/ZoneList";
import LiveVideo from "@/components/Base/LiveVideo";
import Muuri from "muuri";
import { store } from "@/store/index";
import * as Bowser from "bowser";
import { t } from "@eencloud/core-components/src/service/locale";
import { api as fullscreen } from "vue-fullscreen";
import Maps from "@eencloud/core-components/src/components/base/Maps";

export default {
  name: "CameraView",
  components: {
    ZoneList,
    LiveVideo,
    Maps,
  },
  props: ["zones", "cameras", "camera", "rights", "showNavigation"],
  data() {
    return {
      showZoneList: true,
      selectedZone: null,
      multiView: false,
      data0: null,
      data1: null,
      data2: null,
      data3: null,
      data4: null,
      data5: null,
      data6: null,
      data7: null,
      data8: null,
      muted0: true,
      muted1: true,
      muted2: true,
      muted3: true,
      twoZone: false,
      threeZone: false,
      fullscreen: false,
      fullScreenSingleView: false,
      maximize: false,
      overlay: false,
      cursorHide: false,
      browser: null,
      grid: null,
      isFullscreen: false,
      showMap: false,
      cameraCoordinates: null,
    };
  },
  computed: {
    onlineCameras() {
      return this.cameras
        ? this.cameras.filter((c) => {
            return c.status && c.status.online;
          })
        : [];
    },
    cameraSelected() {
      return store.getters.cameraSelected;
    },
    offlineCameras() {
      return this.cameras
        ? this.cameras.filter((c) => {
            return c.status && !c.status.online;
          })
        : [];
    },
    allCameras() {
      return [
        { name: "data0", value: this.data0, position: "0", muted: this.muted0 },
        { name: "data1", value: this.data1, position: "1", muted: this.muted1 },
        { name: "data2", value: this.data2, position: "2", muted: this.muted2 },
        { name: "data3", value: this.data3, position: "3", muted: this.muted3 },
        { name: "data4", value: this.data4, position: "4", muted: true },
        { name: "data5", value: this.data5, position: "5", muted: true },
        { name: "data6", value: this.data6, position: "6", muted: true },
        { name: "data7", value: this.data7, position: "7", muted: true },
        { name: "data8", value: this.data8, position: "8", muted: true },
      ].filter((camera, index) => (this.threeZone ? index <= 8 : index <= 3));
    },
    selectedCameras() {
      if (!this.multiView && this.camera) {
        return [this.camera.cameraId];
      } else if (this.multiView) {
        return [
          this.data0 && this.data0.cameraId,
          this.data1 && this.data1.cameraId,
          this.data2 && this.data2.cameraId,
          this.data3 && this.data3.cameraId,
          this.data4 && this.threeZone && this.data4.cameraId,
          this.data5 && this.threeZone && this.data5.cameraId,
          this.data6 && this.threeZone && this.data6.cameraId,
          this.data7 && this.threeZone && this.data7.cameraId,
          this.data8 && this.threeZone && this.data8.cameraId,
        ].filter((x) => !!x);
      } else {
        return [];
      }
    },
  },
  watch: {
    cameraSelected(newVal, oldVal) {
      this.cameraCoordinates = null;
      let isCameraPresdent = null;
      let selectedArray = JSON.parse(
        this.threeZone ? window.localStorage.getItem("three") : window.localStorage.getItem("two")
      );
      let inArray = this.allCameras.find((ele) => {
        if (ele.value != null) return newVal.id == ele.value.cameraId;
      });

      let counter = 0;
      this.allCameras.map((ele) => {
        if (ele.value) counter++;
      });

      let sortedArray = [...this.allCameras];
      isCameraPresdent = sortedArray.sort((a, b) => {
        return selectedArray.indexOf(a.position) - selectedArray.indexOf(b.position);
      });

      if (this.threeZone && !inArray && counter == 9) {
        this[sortedArray[0].name] = this.cameras.find((cam) => {
          this.$cookies.set("data" + selectedArray[0] + [this.threeZone ? "three" : "two"], cam.cameraId, "4m");
          return newVal.id == cam.cameraId;
        });
      }
      if (!this.threeZone && !inArray && counter == 4) {
        this[sortedArray[0].name] = this.cameras.find((cam) => {
          this.$cookies.set("data" + selectedArray[0] + [this.threeZone ? "three" : "two"], cam.cameraId, "4m");
          return newVal.id == cam.cameraId;
        });
      }

      if (!inArray) {
        sortedArray.every((element) => {
          if (element.value == null) {
            this[element.name] = this.cameras.find((cam) => {
              this.$cookies.set(element.name + [this.threeZone ? "three" : "two"], cam.cameraId, "4m");
              return newVal.id == cam.cameraId;
            });
            return false;
          } else return true;
        });
      }
    },
    allCameras(newVal, oldVal) {
      if (this.multiView) {
        if (newVal != oldVal) {
          let camsId = this.allCameras.map((ele) => {
            if (ele.value) return ele.value.cameraId;
          });
          store.dispatch("allCameras", camsId);
        }
      }
    },
    multiView() {
      store.dispatch("isMultiView", this.multiView);
      if (!this.multiView) {
        this.$cookies.set("grid", 1, "4m");
      } else if (!this.threeZone) {
        this.$cookies.set("grid", 2, "4m");
      }
    },
    threeZone() {
      if (this.threeZone) {
        this.$cookies.set("grid", 3, "4m");
      } else if (this.multiView) {
        this.$cookies.set("grid", 2, "4m");
      }
    },
    cameras() {
      setTimeout(() => this.loadCameras(), 1000);
    },
  },
  methods: {
    selectZone(id) {
      this.selectedZone = id;
      let camsToDisplay = [];
      const onlineCams = this.onlineCameras
        .filter((c) => {
          return this.selectedZone == c.zoneId;
        })
        .sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      const offlineCams = this.offlineCameras
        .filter((c) => {
          return this.selectedZone == c.zoneId;
        })
        .sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      camsToDisplay = [...onlineCams, ...offlineCams];

      if (camsToDisplay.length === 1) {
        this.$router.replace({ name: "Live", params: { cameraId: camsToDisplay[0].cameraId } });
        this.selectCamera(camsToDisplay[0].cameraId);
        this.showMultiView(1);
      } else if (camsToDisplay.length < 5) {
        window.localStorage.setItem("two", '["0","1","2","3"]');
        this.allCameras.map((cam) => {
          this.$cookies.remove(cam.name + ["two"]);
        });
        camsToDisplay[0] && this.$cookies.set("data0two", camsToDisplay[0].cameraId, "4m");
        camsToDisplay[1] && this.$cookies.set("data1two", camsToDisplay[1].cameraId, "4m");
        camsToDisplay[2] && this.$cookies.set("data2two", camsToDisplay[2].cameraId, "4m");
        camsToDisplay[3] && this.$cookies.set("data3two", camsToDisplay[3].cameraId, "4m");
        this.showMultiView(2);
      } else if (camsToDisplay.length) {
        window.localStorage.setItem("three", '["0","1","2","3","4","5","6","7","8"]');
        this.allCameras.map((cam) => {
          this.$cookies.remove(cam.name + ["three"]);
        });
        camsToDisplay[0] && this.$cookies.set("data0three", camsToDisplay[0].cameraId, "4m");
        camsToDisplay[1] && this.$cookies.set("data1three", camsToDisplay[1].cameraId, "4m");
        camsToDisplay[2] && this.$cookies.set("data2three", camsToDisplay[2].cameraId, "4m");
        camsToDisplay[3] && this.$cookies.set("data3three", camsToDisplay[3].cameraId, "4m");
        camsToDisplay[4] && this.$cookies.set("data4three", camsToDisplay[4].cameraId, "4m");
        camsToDisplay[5] && this.$cookies.set("data5three", camsToDisplay[5].cameraId, "4m");
        camsToDisplay[6] && this.$cookies.set("data6three", camsToDisplay[6].cameraId, "4m");
        camsToDisplay[7] && this.$cookies.set("data7three", camsToDisplay[7].cameraId, "4m");
        camsToDisplay[8] && this.$cookies.set("data8three", camsToDisplay[8].cameraId, "4m");
        this.showMultiView(3);
      }
    },
    toggleMaximize() {
      if (this.multiView) {
        fullscreen.toggle(this.$refs["fullscreen"], {
          callback: (fs) => {
            this.isFullscreen = fs;
          },
        });
      } else {
        this.fullScreenSingleView = !this.fullScreenSingleView;
      }
    },

    audioPlay(e) {
      this.allCameras.forEach((element, index) => {
        if (element.value?.cameraId == e) {
          this["muted" + [index]] = false;
        } else {
          this["muted" + [index]] = true;
        }
      });
    },
    showZoneListMethod() {
      this.$nextTick(() => {
        if (this.grid) this.grid.refreshItems().layout();
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 100);
      });
    },
    fullscreenChange(fullscreen) {
      var timeout;
      let that = this;
      var element = document.getElementById("fullScreen");
      this.fullscreen = fullscreen;
      if (this.browser != "Firefox") {
        if (fullscreen) {
          element.requestPointerLock();
          document.onmousemove = function () {
            that.cursorHide = false;
            document.exitPointerLock();
            clearTimeout(timeout);
            timeout = setTimeout(function () {
              that.cursorHide = true;
              element.requestPointerLock();
            }, 2000);
          };
        } else {
          document.onmousemove = null;
          setTimeout(() => {
            that.cursorHide = false;
            document.exitPointerLock();
          }, 2001);
        }
      }
    },
    selectCamera(id) {
      store.dispatch("selectedCamera", { id });
    },
    multiViewShow() {
      this.multiView = !this.multiView;
      if (this.multiView) {
        this.loadCameras();
      }
    },
    showMultiView(zones) {
      this.loadCameras();
      if (zones == 2) {
        this.multiView = true;
        this.threeZone = false;
        setTimeout(() => {
          this.multiViewShow();
          this.overlay = false;
        }, 1);
        this.multiViewShow();
        this.overlay = true;
      }
      if (zones == 3) {
        if (!this.$cookies.isKey("warning")) {
          this.$cookies.set("warning", "true", "4m");
          store.dispatch("toastMessage", {
            showing: true,
            text: t(
              `In order to use a multi view with 9 cameras make sure you have a fast computer and a decent internet connection. In case your computer or internet connection is too slow it will cause pauses in the video stream.`
            ),
            timeout: -1,
            color: "primary",
          });
        }
        this.multiView = true;
        this.threeZone = true;
        setTimeout(() => {
          this.multiViewShow();
          this.overlay = false;
        }, 1);
        this.multiViewShow();
        this.overlay = true;
      }
      if (zones == 1) {
        this.multiView = false;
      }
    },
    camerasForZone(zoneId) {
      return this.filteredCameras.filter((c) => {
        return c.zoneId === zoneId;
      });
    },
    loadCameras() {
      this.allCameras.forEach((element) => {
        this[element.name] = null;
      });
      if ($cookies.isKey("cookieIdentity")) {
        if (this.$store.getters.account) {
          if (this.$store.getters.account.accountId == $cookies.get("cookieIdentity")) {
            this.allCameras.forEach((element) => {
              if ($cookies.isKey(element.name + [this.threeZone ? "three" : "two"])) {
                this[element.name] = this.cameras.find((cam) => {
                  if (cam.cameraId == this.$cookies.get(element.name + [this.threeZone ? "three" : "two"])) {
                    return cam;
                  }
                });
              }
            });
          } else {
            this.$cookies.set("cookieIdentity", this.$store.getters.account.accountId, "4m");
            this.allCameras.forEach((element) => {
              if ($cookies.isKey(element.name)) {
                this.$cookies.remove(element.name + ["three"]);
                this.$cookies.remove(element.name + ["two"]);
              }
            });
          }
        }
      } else {
        this.$cookies.set("cookieIdentity", this.$store.getters.account.accountId, "4m");
        this.allCameras.forEach((element) => {
          if ($cookies.isKey(element.name)) {
            this.$cookies.remove(element.name + ["three"]);
            this.$cookies.remove(element.name + ["two"]);
          }
        });
      }
    },
    drop(ev, selectedCamera) {
      ev.preventDefault();
      let transferDetails = JSON.parse(ev.dataTransfer.getData("text"));
      this.allCameras.forEach((element) => {
        if (this[element.name] && this[element.name].cameraId == transferDetails.cameraId) {
          this[element.name] = null;
          this.$cookies.remove(element.name + [this.threeZone ? "three" : "two"]);
        }
      });
      this[selectedCamera] = transferDetails;
      this.$cookies.set(selectedCamera + [this.threeZone ? "three" : "two"], transferDetails.cameraId, "4m");
    },
    allowdrop(ev) {
      ev.preventDefault();
    },

    dropSingleView(ev) {
      ev.preventDefault();
      this.camera = JSON.parse(ev.dataTransfer.getData("text"));
    },

    removeVideo(selectedStream) {
      this[selectedStream] = null;
      this.$cookies.remove(selectedStream + [this.threeZone ? "three" : "two"]);
    },
  },
  beforeMount() {
    let envData = Bowser.parse(window.navigator.userAgent);
    this.browser = envData.browser.name;
    this.multiView = this.$cookies.get("grid") > 1;
    this.threeZone = this.$cookies.get("grid") == 3;
    if (!window.localStorage.getItem("two")) window.localStorage.setItem("two", '["0","1","2","3"]');
    if (!window.localStorage.getItem("three"))
      window.localStorage.setItem("three", '["0","1","2","3","4","5","6","7","8"]');
    this.loadCameras();
  },

  updated() {
    let that = this;
    if (document.getElementsByClassName("grid").length > 0) {
      initGrid();
    }
    function initGrid() {
      var grid = new Muuri(".grid", {
        dragEnabled: true,
        layoutOnInit: false,
        dragSortPredicate: {
          threshold: 50,
          action: "swap",
        },
        dragStartPredicate: function (item, event) {
          const videoElements = item._child.getElementsByClassName("live-video");
          if (videoElements.length === 0) {
            return false;
          }
          const supportsDewarping = videoElements[0].__vue__.$parent.supportsDewarping;
          const audioControlsEnabled = videoElements[0].__vue__.$parent.showAudioControls;
          if (supportsDewarping && event.target.className.indexOf("toolbar") < 0) {
            return false;
          }
          if (audioControlsEnabled && event.target.className === "v-slider__thumb secondary") {
            return false;
          }
          return Muuri.ItemDrag.defaultStartPredicate(item, event);
        },
      }).on("move", function () {
        saveLayout(grid);
      });
      grid.refreshItems().layout();
      var layout = that.threeZone ? window.localStorage.getItem("three") : window.localStorage.getItem("two");
      if (layout) {
        loadLayout(grid, layout);
      } else {
        grid.layout(true);
      }
      that.grid = grid;
    }

    function serializeLayout(grid) {
      var itemIds = grid.getItems().map(function (item) {
        return item.getElement().getAttribute("data-id");
      });
      return JSON.stringify(itemIds);
    }

    function saveLayout(grid) {
      var layout = serializeLayout(grid);
      that.threeZone ? window.localStorage.setItem("three", layout) : window.localStorage.setItem("two", layout);
    }

    function loadLayout(grid, serializedLayout) {
      var layout = JSON.parse(serializedLayout);
      var currentItems = grid.getItems();
      var currentItemIds = currentItems.map(function (item) {
        return item.getElement().getAttribute("data-id");
      });
      var newItems = [];
      var itemId;
      var itemIndex;

      for (var i = 0; i < layout.length; i++) {
        itemId = layout[i];
        itemIndex = currentItemIds.indexOf(itemId);
        if (itemIndex > -1) {
          newItems.push(currentItems[itemIndex]);
        }
      }
      grid.sort(newItems, { layout: "instant" });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.visibilityHidden {
  visibility: hidden !important;
}

.multiViewGrid {
  height: calc(100% - 36px);
  width: 100%;
}
.multiViewPanel {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.maximize {
  overflow: hidden;
  padding-bottom: 0 !important;
  overflow-y: hidden !important;
}
.dragDrop {
  border: 3px dotted;
  height: 90%;
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c1c1c1;
}
.overlaySidePanel {
  left: 16%;
  transition: none;
}
.overlaySidePanelFull {
  left: 0;
  transition: none;
}
.closeButton {
  position: absolute;
  top: 12px;
  right: 65px;
  z-index: 1;
}
.svg-image {
  width: 24px;
  height: 24px;
  background-color: $color-general-text;
  &.view-1 {
    mask: url("~@eencloud/core-components/src/assets/images/view-1.svg") no-repeat;
  }
  &.view-2x2 {
    mask: url("~@eencloud/core-components/src/assets/images/view-2x2.svg") no-repeat;
  }
  &.view-3x3 {
    mask: url("~@eencloud/core-components/src/assets/images/view-3x3.svg") no-repeat;
  }
  &.selected {
    background-color: var(--v-primary-base);
  }
}
.vl {
  border-left: 2px solid #c1c1c1;
  height: 28px;
  border-radius: 37px;
  position: relative;
  top: 5px;
  margin-right: 6px;
}
.grid {
  position: relative;
  width: 100%;
  height: 100% !important;
  margin-left: 2px;
}
.gridFullScreen {
  top: 0;
  margin-left: 5px;
}

.item {
  position: absolute;
  width: calc(50% - 10px);
  height: calc(50% - 4px);
  margin: 2px;
  padding: 0;
  z-index: 1;
}
.item.muuri-item-hidden {
  z-index: 0;
}
.item.muuri-item-positioning {
  z-index: 2;
}
.item.muuri-item-releasing {
  z-index: 3;
}
.item.muuri-item-dragging {
  z-index: 4;
}
.item-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: contents;
}
.panelfullScreen2 {
  width: 49.5vw !important;
  height: 49.5vh !important;
}
.panelfullScreen3 {
  width: 33vw !important;
  height: 33vh !important;
}
.threeZone {
  width: calc(33% - 3px);
  height: calc(33% - 1px);
}
#live-video {
  width: 100%;
  padding-top: 2px;
  height: calc(100% - 36px);
}
.zone-list {
  background-color: white;
}
.dashboard {
  .dashboard-content {
    background-color: $color-panel-grey;
    min-height: 100vh;
    &.has-navigation {
      min-height: $main-content-height;
      max-height: $main-content-height;
    }
  }
}
</style>
