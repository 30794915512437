<template>
  <v-container fluid class="add-camera ma-0 pa-0">
    <v-row>
      <v-container fluid class="add-camera-content ml-0 my-0 pa-0" :class="{ 'pt-5': addGap }">
        <v-row v-if="addMultipleDevicesMode">
          <AddMultipleCamera 
            @cancel="addMultipleDevicesMode = false"
            ref="addMultipleCamera"
          />
        </v-row>
        <v-row v-else>
          <v-col v-if="showBackButton" class="mb-6" cols="12" offset="2">
            <v-btn text class="ml-12 title unset-title-color" @click="$emit('cancel')">
              <v-icon left>arrow_back</v-icon>
              {{ $t("Back to zones") }}
            </v-btn>
          </v-col>

          <v-col v-if="!qrSetupState.step " cols="10" offset="1" lg="4" offset-lg="4" class="pb-0">
            <div class="mb-4 d-flex justify-space-between align-center">
              <span class="headline">{{ $t("Add a device") }}</span>
              <v-btn @click="addMultipleDevicesMode = true" color="primary">
                {{ $t("Add multiple devices") }}
              </v-btn>
            </div>
            <p
              v-if="model != 2"
              class="body-2"
              v-html="
                $t(
                  'Adding devices is possible through Web App by entering the device MAC address. If your device does not support “Add by MAC address“ please add it via mobile apps'
                )
              "
            ></p>
            <p v-if="model == 1" class="body-2">
              {{ cameraName }} {{ $t("was successfully added") }}.
            </p>
          </v-col>

          <v-col cols="10" offset="1" lg="4" offset-lg="4" class="pb-0" v-if="qrSetupState.step > 1 && !model">
            <v-row>
              <v-col>
              <p class="text-center">{{ $t("This QR-code only works for Body Worn Camera") }}</p>
              <div class="d-flex justify-center">
                <qrcode-vue v-if="!isloading" size="200" :value="qrCode" level="H" />
              </div>
              </v-col>
            </v-row>
            <v-row >
              <v-col class="d-flex justify-center">
                <v-checkbox 
                  @change="value => value ? qrSetupState.step = 3 : qrSetupState.step = 2" 
                  :label="$t('Do you want the bodycam to be added to your account?')">
                </v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          
          <v-col cols="10" offset="1" lg="8" offset-lg="2" class="pb-0" v-if="qrSetupState.step === 1">
            <div class="px-6 text-h6">
              <span class="ml-2">{{ $t("What do you want to set up?") }}</span>
              <v-card class="px-5">
                <v-col cols="6">
                  <v-select
                    class="pt-4"
                    :items="qrSetupState.options"
                    v-model="qrSetupState.choice"
                    filled
                    dense
                  ></v-select>
                </v-col>
              </v-card>
            </div>
            <div class="px-6 text-h6 mt-3" v-if="qrSetupState.choice === 'APN & Wifi' || qrSetupState.choice === 'Wifi' ">
              <span class="ml-2">Wifi {{ $t("Details") }}</span>
              <v-menu max-width="500" nudge-bottom="30" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon size="20" color="primary">fas fa-info-circle</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div>
                      <ul>
                        <li>{{$t("Network name")}}: {{ $t("Name of the network you want to join") }} </li>
                        <li>{{$t("Password")}}: {{ $t("Password of the network you want to join") }} </li>
                      </ul>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-card class="px-8">
                <v-row>
                <v-col>
                  <v-text-field v-model="qrSetupState.wifiSsid" autocomplete="off" :label="$t('Network name')"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="qrSetupState.wifiPassword"
                    autocomplete="off"
                    :append-icon="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                    @click:append="() => (showPassword = !showPassword)"
                    :type="showPassword ? 'text' : 'password'"
                    :label="$t('Password')"
                  ></v-text-field>
                </v-col>
                </v-row>

              </v-card>
            </div>
            <div class="px-6 text-h6 mt-3" v-if="qrSetupState.choice === 'APN & Wifi' || qrSetupState.choice === 'APN' ">
              <span class="ml-2">APN {{ $t("Details") }}</span>
              <v-menu max-width="500" nudge-bottom="30" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon size="20" color="primary">fas fa-info-circle</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div>
                      {{ $t("APN (Access Point Name): The details of your network provider are needed to connect to the internet via a mobile network.") }}
                      {{ $t("Please contact support if you need more details.") }}
                      <ul>
                        <li>{{`${$t("Name")}: ${$t("Mobile Network Name")} `}} </li>
                        <li>APN:  {{ $t("Access Point Name") }} </li>
                        <li>{{`${$t("Username")}: ${$t("Optional user name (optionally combined with password)")} `}} </li>
                        <li>{{`${$t("Password")}: ${$t("Optional password")}` }} </li>
                        <li>MCC: {{ $t("Mobile Country Code") }} </li>
                        <li>MNC: {{ $t("Mobile Network Code") }} </li>
                      </ul>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-card class="px-8">
                <v-row >
                  <v-col cols="6" class="pb-0">
                    <v-text-field v-model="qrSetupState.apnName" autocomplete="off" :label="$t('Name')"></v-text-field>
                    <v-text-field v-model="qrSetupState.apnUrl" autocomplete="off" label="APN"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <v-text-field v-model="qrSetupState.apnUserName" autocomplete="off" :label="$t('Username')"></v-text-field>
                    <v-text-field v-model="qrSetupState.apnMcc" autocomplete="off" label="MCC"></v-text-field>
                  </v-col>
                  <v-col class="pt-0">
                    <v-text-field 
                      v-model="qrSetupState.apnPassword" 
                      autocomplete="off" 
                      :label="$t('Password')"
                      :append-icon="qrSetupState.showApnPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                      @click:append="() => (qrSetupState.showApnPassword = !qrSetupState.showApnPassword)"
                      :type="qrSetupState.showApnPassword ? 'text' : 'password'"
                      ></v-text-field>
                    <v-text-field v-model="qrSetupState.apnMnc" autocomplete="off" label="MNC"></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>

          <v-col cols="10" offset="1" lg="4" offset-lg="4" v-if="qrSetupState.step === 3 || !qrSetupState.step">
            <v-card class="px-6 py-3">
              <v-row justify="space-around"></v-row>
              <v-row>
                <v-carousel
                  touchless
                  :height="$vuetify.breakpoint.xsOnly ? 430 : 400"
                  hide-delimiters
                  :show-arrows="false"
                  v-model="model"
                  :continuous="false"
                  light
                >
                  <v-carousel-item>
                    <h2 class="text-center pb-2">{{ $t("Device details") }}</h2>
                    <v-row>
                      <v-col cols="4">
                        <p class="subheading my-2">{{ $t("Device Name") }}</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="cameraName"
                          flat
                          dense
                          solo
                          :placeholder="$t('New Device')"
                          :error-messages="cameraNameErrors"
                          @input="$v.cameraName.$touch()"
                          @blur="$v.cameraName.$touch()"
                          background-color="grey lighten-3"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <p class="subheading my-2">{{ $t("Device Zone") }}</p>
                      </v-col>
                      <v-col cols="8">
                        <v-autocomplete
                          :search-input.sync="searchInput"
                          flat
                          filled
                          dense
                          :items="zones"
                          :error-messages="selectedZoneErrors"
                          item-text="name"
                          item-value="zoneId"
                          v-model="selectedZone"
                          @input="$v.selectedZone.$touch()"
                          @blur="$v.selectedZone.$touch()"
                        ></v-autocomplete>
                        <v-btn
                          v-if="rights && rights.addZone"
                          outlined
                          color="primary"
                          :ripple="false"
                          @click="addingNewZone = true"
                        >
                          <v-icon left>fas fa-plus</v-icon>
                          {{ $t("Add new zone") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p class="subheading my-2">{{ $t("MAC Address") }}</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-mask="hexMaks"
                          v-model="macAddress"
                          @input="$v.macAddress.$touch()"
                          @blur="$v.macAddress.$touch()"
                          flat
                          dense
                          solo
                          hide-details
                          background-color="grey lighten-3"
                        ></v-text-field>
                        <p
                          class="error-message pt-1 pl-3 caption"
                          v-if="$v.macAddress.$error && !$v.macAddress.required"
                        >
                          {{ $t("MAC Address is a required field") }}
                        </p>
                        <p
                          class="error-message pt-1 pl-3 caption"
                          v-if="$v.macAddress.$error && !$v.macAddress.minLength"
                        >
                          {{ $t("Please enter a valid MAC address") }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-btn
                      color="primary"
                      class="next-button"
                      ref="next-button"
                      :disabled="
                        $v.cameraName.$invalid || $v.selectedZone.$invalid || $v.macAddress.$invalid || isloading
                      "
                      @click="addMac"
                      >{{ $t("Add Device") }}</v-btn
                    >
                    <v-overlay class="add-camera-overlay" opacity=".85" :value="isloading">
                      <een-spinner :size="50" :isloading="isloading" :absolute="false"></een-spinner>
                      <p class="text-center color-white overlay-text">{{ $t("Adding Device") }}</p>
                      <p class="color-white">{{ $t("Please do not reload") }}</p>
                    </v-overlay>
                  </v-carousel-item>
                  <v-carousel-item>
                    <v-img contain height="120" class="camera-image" :src="cloudCamImg"></v-img>
                    <div class="center-align flex-column">
                      <p class="headline text-center">{{ $t("Device added successfully") }}</p>
                      <div class="d-flex  navigation-buttons go-to-dashboard">
                        <v-btn color="primary" block @click="resetForm">{{ $t("Add a new device") }}</v-btn>
                      </div>
                      <div class="d-flex  pt-2 navigation-buttons">
                        <v-btn color="primary" block @click="goToDashboard()">{{ $t("Go to Dashboard") }}</v-btn>
                      </div>
                    </div>
                    <!-- <div class="d-none d-sm-flex">
                      <v-btn color="primary" block class="previous-button" @click="resetForm">{{
                        $t("Add a new Device")
                      }}</v-btn>
                      <v-btn color="primary" block class="next-button" @click="goToDashboard()">{{
                        $t("Go to Dashboard")
                      }}</v-btn>
                    </div> -->
                  </v-carousel-item>
                  <v-carousel-item>
                    <p class="headline text-center">{{ $t("Adding device failed") }}</p>
                    <v-img contain height="120" class="camera-image-unsuccessful" :src="cloudCamImg"></v-img>
                    <div class="center-align flex-column">
                      <h3 class="text-center">{{ statusHeader }}</h3>
                      <p class="camera-unsuccessful-sub-status text-center">{{ subStatus }}</p>
                      <div class="d-flex d-sm-none navigation-buttons go-to-dashboard">
                        <v-btn color="primary" block @click="resetForm">{{ $t("Add a new device") }}</v-btn>
                      </div>
                      <div class="d-flex d-sm-none pt-2 navigation-buttons">
                        <v-btn color="primary" block @click="goToDashboard()">{{ $t("Go to Dashboard") }}</v-btn>
                      </div>
                    </div>
                    <div class="d-none d-sm-flex">
                      <v-btn block color="primary" class="previous-button" @click="resetForm">{{
                        $t("Add a new Device")
                      }}</v-btn>
                      <v-btn block color="primary" class="next-button" @click="goToDashboard()">{{
                        $t("Go to Dashboard")
                      }}</v-btn>
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </v-row>
            </v-card>
            <p class="mt-2" v-if="!qrSetupState.step && themeName !== 'feenstra'" >
              <a @click="qrSetupState.step = 1">{{ $t("Add Body Worn Camera") }}</a>
            </p>
          </v-col>
          <v-col cols="10" offset="1" :lg="qrSetupState.step < 2 ? 8 : 4" :offset-lg="qrSetupState.step < 2 ? 2 : 4" align="end" v-if="!model">
            <v-btn 
              v-if="qrSetupState.step > 0 && !isloading" 
              @click="qrSetupState.step = 0"
              color="light"
              class="mx-5"
            >{{ $t("Cancel") }}
            </v-btn>
            <v-btn 
              v-if="qrSetupState.step > 1 && !isloading" 
              @click="qrSetupState.step = qrSetupState.step === 3 ? qrSetupState.step -2 : qrSetupState.step -1"
              color="primary"
              class="mx-5"
            >{{ $t("Back") }}
            </v-btn>
            <v-btn 
              v-if="qrSetupState.step === 1 && !isloading" 
              @click="qrSetupState.step = 2"
              color="primary"
              class="mx-5"
              :disabled="!QRFormValid"
            >{{ $t("Next") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-dialog v-model="addingNewZone" max-width="400">
      <v-card>
        <v-card-title primary-title> </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newZoneName"
            :label="$t('Zone name')"
            :error-messages="newZoneNameErrors"
            @input="$v.newZoneName.$touch()"
            @blur="$v.newZoneName.$touch()"
            @keyup.enter="addZone"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <een-spinner :size="50" :isloading="zoneSpinner"></een-spinner>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" :disabled="$v.newZoneName.$invalid || zoneSpinner" @click="addZone">
            {{ $t("Add new zone") }}
          </v-btn>
          <v-btn depressed @click="cancelAddZone">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import { store } from "@/store";
import Theme from "@eencloud/core-components/src/service/themes";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import Sentry from "@eencloud/core-components/src/plugins/sentry";
import QrcodeVue from "qrcode.vue";
import AddMultipleCamera from "@/components/AddMultipleCamera.vue";
import { getCameraErrorMsg } from "@/service/cameraErrors";

export default {
  name: "AddCamera",
  components: {
    QrcodeVue,
    AddMultipleCamera
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
    addGap: {
      type: Boolean,
      default: true,
    },
    rights: {
      type: Object,
    },
  },
  directives: { mask },
  data() {
    return {
      checkModels: false,
      showPassword: "",
      cloudCamImg: require("@eencloud/core-components/src/assets/images/cloudcams.svg"),
      cameraName: "",
      macAddress: store.getters.device_MAC_address ? store.getters.device_MAC_address : "",
      hexMaks: {
        mask: "FF-FF-FF-FF-FF-FF",
        tokens: {
          F: {
            pattern: /[0-9a-fA-F]/,
            transform: (v) => v.toLocaleUpperCase(),
          },
        },
      },
      model: 0,
      zones: [],
      themeName: '',
      selectedZone: null,
      addingNewZone: false,
      newZoneName: null,
      isloading: false,
      links: {},
      zoneSpinner: false,
      cameraId: null,
      overlay: false,
      searchInput: null,
      subStatus: "",
      statusHeader: "",
      qrSetupState: {
        step: 0, // 0: disabled, 1: qr details form, 2: qr code, step 3: show add by mac 
        options: ["APN & Wifi", "Wifi", "APN"],
        choice: "APN & Wifi",
        wifiPassword: "",
        wifiSsid: "",
        apnName: "",
        apnUrl: "",
        apnUserName: "",
        apnPassword: "",
        apnMcc: "",
        apnMnc: "",
        showApnPassword: false
      }, 
      addMultipleDevicesMode: false,
    };
  },
  computed: {
    isMobile() {
      return ["iOS", "Android"].includes(this.getOS());
    },
    mask() {
      const F = {
        pattern: /[0-9a-fA-F]/,
        transform: (v) => v.tozoneSpinnereUpperCase(),
      };

      return "XX-XX-XX-XX-XX-XX";
    },

    qrCodeApnLine() {
      if (this.qrSetupState.choice === "APN & Wifi" || this.qrSetupState.choice === "APN") {
        // username and password are optional
        const username = this.qrSetupState.apnUserName ? `|${window.btoa(this.qrSetupState.apnUserName)}` : ""
        const password = this.qrSetupState.apnUserName ? `|${window.btoa(this.qrSetupState.apnPassword)}` : ""
       return `B=${this.qrSetupState.apnName}|${this.qrSetupState.apnUrl}|${this.qrSetupState.apnMcc}|${this.qrSetupState.apnMnc}${username}${password}`
     } else return ""
    },
    qrCodeWifiLine() {
     if (this.qrSetupState.choice === "APN & Wifi" || this.qrSetupState.choice === "Wifi") {
       return `4=${window.btoa(this.qrSetupState.wifiSsid)}/${window.btoa(this.qrSetupState.wifiPassword)}`
     } else return ""
    },

    qrCode() { // don't mess with the spacings in the interpolated string
      return `3=en
${this.qrCodeWifiLine}
${this.qrCodeApnLine}`
    },
    newZoneNameErrors() {
      const errors = [];
      if (!this.$v.newZoneName.$dirty) return errors;
      if (!this.$v.newZoneName.required) errors.push(this.$t("Zone name is a required field"));
      if (!this.$v.newZoneName.minLength || !this.$v.newZoneName.maxLength) {
        errors.push(
          this.$t("Zone name should be X-Y characters", {
            min: this.$v.newZoneName.$params.minLength.min,
            max: this.$v.newZoneName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
    cameraNameErrors() {
      const errors = [];
      if (!this.$v.cameraName.$dirty) return errors;
      if (!this.$v.cameraName.required) errors.push(this.$t("Device name is a required field"));
      if (!this.$v.cameraName.maxLength) {
        errors.push(this.$t("Device name should be max 32 characters"));
      }
      return errors;
    },
    selectedZoneErrors() {
      const errors = [];
      if (!this.$v.selectedZone.$dirty) return errors;
      if (!this.$v.selectedZone.required) errors.push(this.$t("Device Zone is a required field"));
      return errors;
    },
    QRFormValid() {
      const apnValid = !!(this.qrSetupState.apnName && this.qrSetupState.apnName && this.qrSetupState.apnUrl && this.qrSetupState.apnMcc && this.qrSetupState.apnMnc)
      const wifiValid = !!this.qrSetupState.wifiSsid
      if(this.qrSetupState.choice === "APN & Wifi") {
        return wifiValid && apnValid
      } else if (this.qrSetupState.choice === "APN") {
        return apnValid
      } else if (this.qrSetupState.choice === "Wifi") {
        return wifiValid
      }
    }
  },

  mounted() {
    Sentry.configureScope((scope) => scope.setTransactionName(this.$options.name));
    this.themeName = config.hasOwnProperty("theme") ? config.theme : "Cameramanager";
    const theme = new Theme(this.themeName);
    this.links = theme.links();
    const that = this;
    this.restapi.getZones().then(function (zones) {
      that.zones = zones;
    });
    window.addEventListener("keydown", this.nextStep);
    if (this.$route.params) {
      this.selectedZone = this.$route.params.zoneId;
      this.searchInput = this.zones.find((zone) => this.selectedZone === zone.zoneId);
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.nextStep);
  },
  beforeRouteLeave(to, from, next) {
    const isDirty = this.$refs.addMultipleCamera? this.$refs.addMultipleCamera.isDirty: undefined;
    if (isDirty === true) {
      const answer = window.confirm(
        this.$t("Changes you made may not be saved")
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    resetForm() {
      this.macAddress = "";
      this.cameraName = "";
      this.selectedZone = null;
      this.qrSetupState.step = 0
      this.qrSetupState.choice = "APN & Wifi"
      this.qrSetupState.wifiPassword = ""
      this.qrSetupState.wifiSsid = ""
      this.qrSetupState.apnName = ""
      this.qrSetupState.apnUrl = ""
      this.qrSetupState.apnUserName = ""
      this.qrSetupState.apnPassword = ""
      this.qrSetupState.apnMcc = ""
      this.qrSetupState.apnMnc = ""
      this.qrSetupState.showApnPassword = false
      store.dispatch("setDeviceMAC", null);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.model = 0;
      this.searchInput = "";
    },
    async addMac() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage")
      }
      const that = this;
      this.isloading = true;
      try {
        const addCameraByMAC = await this.restapi.addDeviceByMAC(this.cameraName, this.selectedZone, this.macAddress);
        this.cameraId = addCameraByMAC.deviceId;
        try {
          const addCameraStatusPoll = await this.restapi.addDeviceStatusPoll(this.cameraId);
          this.isloading = false;
          this.$emit("loadCameras");
          if (addCameraStatusPoll.status == "added") {
            this.model++;
          } else {
            const text = getCameraErrorMsg(addCameraStatusPoll.subStatus);
            this.subStatus = store.dispatch("toastMessage", {
              text,
              color: "error",
              showing: true,
              timeout: -1,
              support: true
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          console.log("sunnel error", error);
          that.isloading = false;
          store.dispatch("toastMessage", {
            showing: true,
            text: getMessageFromError(error, "ADD_DEVICE_STATUS_POLL"),
            timeout: -1,
            support: true,
            color: "error",
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log("add device mac error", error);
        this.isloading = false;
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "ADD_DEVICE"),
          timeout: -1,
          support: true,
          color: "error",
        });
      }
    },
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    downloadClient() {
      if (this.clients.hasOwnProperty(this.getOS())) {
        if (this.$ga !== undefined) {
          this.$ga.event("Add Camera", "Download client app", this.getOS());
        }
        const link = this.$refs.downloadLink;
        link.href = this.clients[this.getOS()];
        link.click();
      }
    },
    gotoStore(os) {
      if (this.$ga !== undefined) {
        this.$ga.event("Add Camera", "Go to store", os);
      }
      const link = this.$refs.downloadLink;
      link.href = this.clients[os];
      link.click();
    },
    cancelAddZone() {
      this.addingNewZone = false;
      this.newZoneName = null;
    },
    addZone() {
      this.zoneSpinner = true;
      if (!this.$v.newZoneName.$invalid) {
        const that = this;
        this.restapi
          .addZone(this.newZoneName)
          .then(function (zone) {
            that.$emit("added", zone);
            that.selectedZone = zone.zoneId;
          })
          .then(function () {
            that.restapi.getZones().then(function (zones) {
              that.zones = zones;
              that.newZoneName = null;
              that.addingNewZone = false;
              that.zoneSpinner = false;
            });
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    nextStep(event) {
      if (
        event.key == "Enter" &&
        this.model == 0 &&
        !this.$v.cameraName.$invalid &&
        !this.$v.selectedZone.$invalid &&
        !this.$v.macAddress.$invalid &&
        !this.isloading
      )
        this.addMac();
    },
  },
  validations() {
    return {
      newZoneName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
      cameraName: {
        required,
        maxLength: maxLength(32),
      },
      selectedZone: {
        required,
      },
      macAddress: {
        required,
        minLength: minLength(17),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.add-camera {
  .add-camera-content {
    background-color: $color-panel-grey;
    min-height: $main-content-height;
  }
}

.steps {
  color: black;
  padding: 0 5px;
}

.previous-button {
  position: absolute;
  left: 0;
  bottom: 0;
}
.next-button {
  position: absolute;
  right: 0;
  bottom: 0;
}
.steps-add-camera {
  display: flex;
  flex-direction: column;
  height: 80%;
}

.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-camera-card {
  height: 100%;
}
.add-mac-text {
  border-right: 1px solid #d6d6d6;
}
.mac-button {
  background: #eeeeee;
  height: 38px;
  width: 100%;
  padding: 13px;
  border-radius: 4px;
}
.mac-error-message {
  font-size: 12px;
}
.een-spinner {
  position: absolute;
  top: 42%;
  right: 33%;
}
.information-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 28px;
  width: 28px;
}
.navigation-buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.go-to-dashboard {
  bottom: 45px;
}
.camera-image {
  margin-top: 85px;
}

.camera-image-unsuccessful {
  margin-top: 35px;
  margin-bottom: 35px;
}

.camera-unsuccessful-sub-status {
  padding: 0 25px;
}

@media (max-width: 450px) {
  .camera-image-unsuccessful {
    margin-top: 0px;
    margin-bottom: 0;
  }
  .camera-unsuccessful-sub-status {
    padding: 0;
  }
}

.overlay-text {
  margin-top: 175px;
}
.color-white {
  color: #ffffff;
}
</style>
